<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 15:08:07
 * @LastEditors: zhaoqianqian
 * @LastEditTime: 2021-11-10 14:50:12
-->
<template>
    <div class="exception-page">
        <exception-page :exception="exception"></exception-page>
    </div>
</template>

<script>
import ExceptionPage from '@/components/exception/Exception'
import image from '@/assets/images/excep_binding_def.png'

export default {
    components: {
        ExceptionPage
    },
    data() {
        return {
            exception: {
                title: '',
                desc: this.$t('modelBinding.desc'),
                loader: false,
                picture: image
            }
        }
    }
}
</script>

<style scoped lang="less">
/deep/ .exception-wrapper > p {
    padding: 0 32px 0 32px;
}
</style>